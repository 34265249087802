import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { fetchStudentDetailsAPI, fetchCounselorDetailsGETAPI, fetchAdminDetailsGETAPI } from '../../services/api/userDetails';
const initialState = {
    error: "",
    studentDetailsFetching: false,
    counselorDetailsFetching: false,
    adminDetailsFetching: false,
    adminDetails: {},
    counselorDetails: {},
    studentDetails: {},
    errorMessage: ''
};

export const fetchStudentData = createAsyncThunk(
    'fetchstudentdetails',
    async (params, { rejectedWithValue }) => {
        try {
            const res = await fetchStudentDetailsAPI(params.studentIdNew, params.studentAuthTokenNew);
            return res?.data;
        } catch (err) {
            return rejectedWithValue(err);
        }
    }
);
export const fetchCounselorData = createAsyncThunk(
    'fetchcounselordetails',
    async (authToken, { rejectedWithValue }) => {
        try {
            const res = await fetchCounselorDetailsGETAPI(authToken);
            return res?.data;
        } catch (err) {
            return rejectedWithValue(err);
        }
    }
);
export const fetchAdminData = createAsyncThunk(
    'fetchadmindetails',
    async (authToken, { rejectedWithValue }) => {
        try {
            const res = await fetchAdminDetailsGETAPI(authToken);
            return res?.data;
        } catch (err) {
            return rejectedWithValue(err);
        }
    }
);

export const resetUserDetails = createAsyncThunk(
    'resetUserDetails',
    async () => {
        return false;
    }
)
const userDetailsAPIDataSlice = createSlice({
    name: 'userDetailsData',
    initialState,
    reducers: {
        logoutSuccessUserDetails: (state) => {
            // state.studentDetails= {};
            // state.counselorDetails= {};
            // state.adminDetails= {};
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStudentData.pending, (state) => {
            state.studentDetailsFetching = true
        });
        builder.addCase(fetchStudentData.fulfilled, (state, action) => {
            state.studentDetails = action.payload;

        });
        builder.addCase(fetchStudentData.rejected, (state, action) => {
            state.studentDetailsFetching = false,
                state.errorMessage = action.payload
        })
        builder.addCase(fetchCounselorData.pending, (state) => {
            state.counselorDetailsFetching = true
        });
        builder.addCase(fetchCounselorData.fulfilled, (state, action) => {
            state.counselorDetails = action.payload
        });
        builder.addCase(fetchCounselorData.rejected, (state, action) => {
            state.counselorDetailsFetching = false,
                state.errorMessage = action.payload
        })



        builder.addCase(fetchAdminData.pending, (state) => {
            state.adminDetailsFetching = true
        });
        builder.addCase(fetchAdminData.fulfilled, (state, action) => {
            state.adminDetails = action.payload
        });
        builder.addCase(fetchAdminData.rejected, (state, action) => {
            state.adminDetailsFetching = false,
                state.errorMessage = action.payload
        })

        builder.addCase(resetUserDetails.pending, (state) => {
            state.studentDetailsFetching = false;
            state.counselorDetailsFetching = false;
            state.adminDetailsFetching = false;
        });

        builder.addCase(resetUserDetails.fulfilled, (state, action) => {
            state.adminDetails = {};
            state.studentDetails = {};
            state.counselorDetails= {};
        });
    }
});




export const { logoutSuccessUserDetails } = userDetailsAPIDataSlice.actions;
export default userDetailsAPIDataSlice.reducer;